import { Controller } from '@hotwired/stimulus';
// import dayjs from 'dayjs';

// import { getMetaValue } from './helpers';

export default class extends Controller {
  static targets = [
    'errors', 'content', 'form'
  ];

  createSuccess(event) {
    const [data, _status, xhr] = event.detail;

    // this.costPerLiterTarget.innerHTML = data.cost_per_liter;

    // this.userNameTarget.innerHTML = data.user.name;
    // this.createdAtTarget.innerHTML = dayjs(data.created_at).format('DD/MM/YYYY HH:mm');

    // this.formCostPerLiterTarget.value = data.cost_per_liter;

    this.closeEdit();
  }

  showErrors(event) {
    const [_data, _status, xhr] = event.detail;

    this.errorsTarget.innerHTML = JSON.parse(xhr.response).join('; ');
  }

  showEdit() {
    console.log('showEdit clicked');

    this.contentTarget.classList.remove('d-block');
    this.contentTarget.classList.add('d-none');

    this.formTarget.classList.remove('d-none');
    this.formTarget.classList.add('d-block');
  }

  closeEdit() {
    this.contentTarget.classList.remove('d-none');
    this.contentTarget.classList.add('d-block');

    this.formTarget.classList.remove('d-block');
    this.formTarget.classList.add('d-none');

    this.errorsTarget.innerHTML = '';
  }
}
