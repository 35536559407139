import { Controller } from '@hotwired/stimulus'

// Mask
import jQuery from 'jquery'
import 'jquery-mask-plugin/dist/jquery.mask.min'

// Datepicker
import flatpickr from 'flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import 'flatpickr/dist/flatpickr.min.css';

export default class extends Controller {
  connect() {
    jQuery(this.element).mask('00/0000')
  }
}
